import React from "react";
import { Col, Card } from "react-bootstrap";
import SobreMiSkills from "./SobreMiSkills";

const SobreMiTexto = () => {
  return (
    <Col className="col-12 col-lg-8 mx-auto">
      <Card className="bg-white shadow card-body marg-ab-card3">
        <h1 className="card-title m-md-4 text-start subtitulo arq">
          architecture
        </h1>
        <p className="card-text m-sm-2  m-md-4 just">
          I had a love for architecture since I was a child. It was influenced
          then by my fascination with archeology and mythology, which now I see
          as a means of exploring the cultural and social contexts of the built
          environment. This led me to study architecture in college, where I
          discovered modern architecture, which combined my interests in art and
          history, and get fascinated by the endless possibilities of
          architectural design.
          <br />
          <br />
          As a young architect, I am inspired by the innovative use of
          materials, shapes, and construction methods to create buildings that
          are both functional and visually appealing, always with sustainability
          in mind. For me, architecture is about creating experiences that are
          not only practical but also stimulate the senses and engage the mind;
          I based my thesis on this concept,{" "}
          <a
            className="solo-subrayado hover"
            href="https://sisbibliotecas.ort.edu.uy/cgi-bin/koha/opac-detail.pl?biblionumber=84263"
            target={"_blank"}
            rel="noreferrer"
          >
            {" "}
            obtaining a grade of 87/100.
          </a>
          <br />
          <br />I see architecture as a means of exploring the human experience,
          revealing the values and beliefs of different societies throughout
          time. In my designs, I try to create rich narratives and immerse
          visitors in the experience. To me, architecture is not just about
          creating buildings but also about creating a sense of connection
          between people, places, and times. I am committed to using my skills
          to create buildings that are both functional and meaningful for my
          clients.
        </p>
      </Card>
      <Card className="bg-white shadow card-body marg-ab-card3">
        <h1 className="card-title m-md-4 text-start subtitulo des">design</h1>
        <p className="card-text m-sm-2  m-md-4 just">
          As an architect who is passionate about design, I found that my
          proficiency in software design tools and knowledge of design and
          styles enabled me to better understand the needs of clients in graphic
          design and marketing. By leveraging my expertise, I brought a unique
          and innovative perspective to their projects, creating compelling
          solutions that set their brands apart.
          <br />
          <br />
          My architecture degree provided me with a strong foundation in design
          principles and aesthetics, which I was able to apply to graphic design
          and marketing. Combined with my design software skills, it allowed me
          to create visually appealing graphics that effectively communicated
          messages. By working closely with clients, I gained a deeper
          understanding of their brand's narrative and was able to reflect their
          values and goals.
          <br />
          <br />I have always loved designing, even for fun, helping friends
          with card designs, invitations, logos, and more. Throughout my career,
          I’ve applied the same project management principles I learned in
          architecture to lead and inspire creative teams in design and
          marketing. By integrating project management tools, I’ve been able to
          streamline processes and amplify brand impact. I’m continually driven
          to weave design into everything I do, constantly seeking new ways to
          elevate projects and make them visually compelling.
        </p>
      </Card>
      <Card className="bg-white shadow card-body marg-ab-card3">
        <h1 className="card-title m-md-4 text-start subtitulo prog">
          programming
        </h1>
        <p className="card-text m-sm-2  m-md-4 just">
          I feel like incorporating programming into my areas of expertise came
          naturally. During my childhood, since my father is an engineer, I had
          access to a computer, and I feel comfortable interacting with them.
          I'm a geek, so I always followed the technology news and was aware of
          the latest trends.
          <br />
          <br />
          The pandemic began in March 2020, and with the general uncertainty and
          movement restrictions, I decided to occupy part of this new free time
          that teleworking grants, to start a degree as a programmer analyst at
          the ORT university that August. Now I am very happy with the results
          in the learning that I have obtained in these two and a half years,
          and willing to keep improving and learning.
          <br />
          <br />
          My knowledge in the area of programming allows me to offer
          comprehensive solutions tailored to the needs of every client.
        </p>
      </Card>
      <Card className="bg-white shadow card-body marg-ab-card1">
        <h1 className="card-title m-md-4 text-end subtitulo">the future</h1>
        <p className="card-text m-sm-2  m-md-4 just">
          As I prepare to start my final project in August, I am excited to
          merge my two passions of architecture and technology. I believe that
          the future of architecture lies in the integration of technology, and
          I am eager to explore the possibilities that this intersection offers.
          By using technology to optimize and streamline the design and
          construction process, I hope to create more sustainable and efficient
          buildings that benefit both the environment and society.
          <br />
          <br />
          After completing my capstone project for my Programmer Analyst degree,
          I plan to continue learning about cutting-edge technologies like
          artificial intelligence, generative AI, and machine learning. I
          believe that these fields have the potential to revolutionize the way
          we design and construct buildings. By applying this knowledge in the
          architecture field through BIM, I hope to create more intelligent and
          dynamic buildings that can adapt to changing user needs and
          environmental conditions.
        </p>
      </Card>
      <p className="sub1">updated July 2023</p>
      <br />
      <br />
      <SobreMiSkills />
    </Col>
  );
};

export default SobreMiTexto;
