import React from "react";
import { Card } from "react-bootstrap";
import "../inicio/inicio.css";
import ImagenGithub from "../imagenes/ImagenGithub";

const InicioGithub = () => {
  return (
    <Card
      className="bg-white shadow card-body marg-lados marg-ab-card2"
      style={{ height: "auto" }}
    >
      <ImagenGithub className="arr-der tamano7" />
      <h3 className="card-title h3-inicio">#GitHub</h3>
      <a
        className="deco-no hover"
        href="https://github.com/n1colasf"
        target={"_blank"}
        rel="noreferrer"
      >
        <h3 className="card-title sm-txt">@n1colasf</h3>{" "}
      </a>
      <p className="card-text">
        <br />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 24 24"
        >
          <path d="M6.25 12a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5ZM5.5 9.25a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75ZM6.25 5a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5ZM9 12.75a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75Zm.75-4.25a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5ZM9 5.75A.75.75 0 0 1 9.75 5h.5a.75.75 0 0 1 0 1.5h-.5A.75.75 0 0 1 9 5.75ZM13.25 12a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5Zm-.75-2.75a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75ZM13.25 5a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5Z" />
          <path d="M2 20V3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v17c0 .173-.022.34-.063.5H20a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.2-.4l-.5-.375a.75.75 0 0 1 .9-1.2l.5.375c.504.378.8.97.8 1.6v8a2 2 0 0 1-2 2h-3.562a.767.767 0 0 1-.166-.018c-.089.012-.18.018-.272.018h-3.75a.75.75 0 0 1-.75-.75V19h-3v2.25a.75.75 0 0 1-.75.75H4a2 2 0 0 1-2-2Zm2 .5h3v-2.25a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75v2.25h3a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H4a.5.5 0 0 0-.5.5v17a.5.5 0 0 0 .5.5Z" />
        </svg>{" "}
        <a
          className="deco-no hover"
          href="https://github.com/pi-ati-ort"
          target={"_blank"}
          rel="noreferrer"
        >
          Final Project IT Analyst
        </a>
        <br />
        <svg
          aria-hidden="true"
          height="14"
          viewBox="0 0 16 16"
          version="1.1"
          width="14"
          data-view-component="true"
          class="octicon octicon-repo"
        >
          <path d="M2 2.5A2.5 2.5 0 0 1 4.5 0h8.75a.75.75 0 0 1 .75.75v12.5a.75.75 0 0 1-.75.75h-2.5a.75.75 0 0 1 0-1.5h1.75v-2h-8a1 1 0 0 0-.714 1.7.75.75 0 1 1-1.072 1.05A2.495 2.495 0 0 1 2 11.5Zm10.5-1h-8a1 1 0 0 0-1 1v6.708A2.486 2.486 0 0 1 4.5 9h8ZM5 12.25a.25.25 0 0 1 .25-.25h3.5a.25.25 0 0 1 .25.25v3.25a.25.25 0 0 1-.4.2l-1.45-1.087a.249.249 0 0 0-.3 0L5.4 15.7a.25.25 0 0 1-.4-.2Z"></path>
        </svg>{" "}
        <a
          className="deco-no hover"
          href="https://github.com/n1colasf/Obligatorio-BD"
          target={"_blank"}
          rel="noreferrer"
        >
          n1colasf/Obligatorio-BD
        </a>
        <br />
        <svg
          aria-hidden="true"
          height="14"
          viewBox="0 0 16 16"
          version="1.1"
          width="14"
          data-view-component="true"
          class="octicon octicon-repo"
        >
          <path d="M2 2.5A2.5 2.5 0 0 1 4.5 0h8.75a.75.75 0 0 1 .75.75v12.5a.75.75 0 0 1-.75.75h-2.5a.75.75 0 0 1 0-1.5h1.75v-2h-8a1 1 0 0 0-.714 1.7.75.75 0 1 1-1.072 1.05A2.495 2.495 0 0 1 2 11.5Zm10.5-1h-8a1 1 0 0 0-1 1v6.708A2.486 2.486 0 0 1 4.5 9h8ZM5 12.25a.25.25 0 0 1 .25-.25h3.5a.25.25 0 0 1 .25.25v3.25a.25.25 0 0 1-.4.2l-1.45-1.087a.249.249 0 0 0-.3 0L5.4 15.7a.25.25 0 0 1-.4-.2Z"></path>
        </svg>{" "}
        <a
          className="deco-no hover"
          href="https://github.com/n1colasf/Obligatorio-DDA"
          target={"_blank"}
          rel="noreferrer"
        >
          n1colasf/Obligatorio-DDA
        </a>
        {/* <br />
          <svg
          aria-hidden="true"
          height="14"
          viewBox="0 0 16 16"
          version="1.1"
          width="14"
          data-view-component="true"
          class="octicon octicon-repo"
        >
          <path d="M2 2.5A2.5 2.5 0 0 1 4.5 0h8.75a.75.75 0 0 1 .75.75v12.5a.75.75 0 0 1-.75.75h-2.5a.75.75 0 0 1 0-1.5h1.75v-2h-8a1 1 0 0 0-.714 1.7.75.75 0 1 1-1.072 1.05A2.495 2.495 0 0 1 2 11.5Zm10.5-1h-8a1 1 0 0 0-1 1v6.708A2.486 2.486 0 0 1 4.5 9h8ZM5 12.25a.25.25 0 0 1 .25-.25h3.5a.25.25 0 0 1 .25.25v3.25a.25.25 0 0 1-.4.2l-1.45-1.087a.249.249 0 0 0-.3 0L5.4 15.7a.25.25 0 0 1-.4-.2Z"></path>
        </svg>{" "}
        <a
          className="deco-no hover"
          href="https://github.com/n1colasf/Obligatorio-TGX"
          target={"_blank"}
          rel="noreferrer"
        >
          n1colasf/Obligatorio-TGX
        </a>
        */}
        <br />
        <svg
          aria-hidden="true"
          height="14"
          viewBox="0 0 16 16"
          version="1.1"
          width="14"
          data-view-component="true"
          class="octicon octicon-repo"
        >
          <path d="M2 2.5A2.5 2.5 0 0 1 4.5 0h8.75a.75.75 0 0 1 .75.75v12.5a.75.75 0 0 1-.75.75h-2.5a.75.75 0 0 1 0-1.5h1.75v-2h-8a1 1 0 0 0-.714 1.7.75.75 0 1 1-1.072 1.05A2.495 2.495 0 0 1 2 11.5Zm10.5-1h-8a1 1 0 0 0-1 1v6.708A2.486 2.486 0 0 1 4.5 9h8ZM5 12.25a.25.25 0 0 1 .25-.25h3.5a.25.25 0 0 1 .25.25v3.25a.25.25 0 0 1-.4.2l-1.45-1.087a.249.249 0 0 0-.3 0L5.4 15.7a.25.25 0 0 1-.4-.2Z"></path>
        </svg>{" "}
        <a
          className="deco-no hover"
          href="https://github.com/n1colasf/Obligatorio-TDFE"
          target={"_blank"}
          rel="noreferrer"
        >
          n1colasf/Obligatorio-TDFE
        </a>
        <br />
        <svg
          aria-hidden="true"
          height="14"
          viewBox="0 0 16 16"
          version="1.1"
          width="14"
          data-view-component="true"
          class="octicon octicon-repo"
        >
          <path d="M2 2.5A2.5 2.5 0 0 1 4.5 0h8.75a.75.75 0 0 1 .75.75v12.5a.75.75 0 0 1-.75.75h-2.5a.75.75 0 0 1 0-1.5h1.75v-2h-8a1 1 0 0 0-.714 1.7.75.75 0 1 1-1.072 1.05A2.495 2.495 0 0 1 2 11.5Zm10.5-1h-8a1 1 0 0 0-1 1v6.708A2.486 2.486 0 0 1 4.5 9h8ZM5 12.25a.25.25 0 0 1 .25-.25h3.5a.25.25 0 0 1 .25.25v3.25a.25.25 0 0 1-.4.2l-1.45-1.087a.249.249 0 0 0-.3 0L5.4 15.7a.25.25 0 0 1-.4-.2Z"></path>
        </svg>{" "}
        <a
          className="deco-no hover"
          href="https://github.com/n1colasf/Obligatorio-AED2"
          target={"_blank"}
          rel="noreferrer"
        >
          n1colasf/Obligatorio-AED2
        </a>
        <br />
        <svg
          aria-hidden="true"
          height="14"
          viewBox="0 0 16 16"
          version="1.1"
          width="14"
          data-view-component="true"
          class="octicon octicon-repo"
        >
          <path d="M2 2.5A2.5 2.5 0 0 1 4.5 0h8.75a.75.75 0 0 1 .75.75v12.5a.75.75 0 0 1-.75.75h-2.5a.75.75 0 0 1 0-1.5h1.75v-2h-8a1 1 0 0 0-.714 1.7.75.75 0 1 1-1.072 1.05A2.495 2.495 0 0 1 2 11.5Zm10.5-1h-8a1 1 0 0 0-1 1v6.708A2.486 2.486 0 0 1 4.5 9h8ZM5 12.25a.25.25 0 0 1 .25-.25h3.5a.25.25 0 0 1 .25.25v3.25a.25.25 0 0 1-.4.2l-1.45-1.087a.249.249 0 0 0-.3 0L5.4 15.7a.25.25 0 0 1-.4-.2Z"></path>
        </svg>{" "}
        <a
          className="deco-no hover"
          href="https://github.com/n1colasf/Obligatorio-P3"
          target={"_blank"}
          rel="noreferrer"
        >
          n1colasf/Obligatorio-P3
        </a>
      </p>
    </Card>
  );
};

export default InicioGithub;
