import "./arquitectura.css";

import React, { Fragment } from "react";

const ArquitecturaTexto = () => {
  return (
    <>
      <div className="me-md-3 me-lg-0 me-4 ms-3 mt-4">
        <h4 className="h-arq derecha">university</h4>
        <p className="card-text">
          The moment I set foot at the ORT Uruguay University, I knew it was the{" "}
          <a
            href="https://www.ort.edu.uy/novedades/unica-universidad-uruguaya-en-el-latin-america-university-rankings-2023"
            target="_blank"
            rel="noreferrer"
            className="enlace-arq"
          >
            perfect place
          </a>{" "}
          to pursue my architectural dreams in a dynamic and diverse learning
          environment. Here, I had the opportunity to get into architectural
          principles and design fundamentals, gaining a profound understanding
          of the craft. The chance to work on real-world projects allowed me to
          nurture my creativity, sharpen my critical thinking, and develop
          robust problem-solving skills.
        </p>
        <h4 className="h-arq derecha">beginnings</h4>
        <p className="card-text">
          I began immersing myself in the world of architecture practice through
          technical drawing, which allowed me to develop the ability to
          represent spaces in two and three dimensions. With each project, I
          gained a deeper understanding of the techniques and regulations
          involved in architectural design. This experience instilled in me the
          skills to take the first steps in my career.
        </p>
        <h4 className="h-arq derecha">LCU </h4>
        <p className="card-text">
          La Casa Uruguaya (LCU) started back in 2014 as a faculty project to
          participate in the{" "}
          <a
            href="https://www.solardecathlon.gov/"
            target="_blank"
            rel="noreferrer"
            className="enlace-arq"
          >
            {" "}
            Solar Decathlon
          </a>
          , a worldwide competition about solar and sustainable architecture,
          hosted in Latin America for the first time in December 2015, where LCU
          was awarded the{" "}
          <a
            href="https://www.lacasauruguaya.com/en/solar-decathlon/"
            target="_blank"
            rel="noreferrer"
            className="enlace-arq"
          >
            first prize
          </a>
          . Established in 2016 as a company by a team of architects and
          engineers, pioneer in the development of sustainable wood architecture
          in Uruguay.
          <br />
          Since then, I have been working as an architect, gaining experience
          and honing my expertise in architectural design, project and
          construction management, and client communication. These experiences
          have taught me the importance of creative problem - solving and
          attention to detail, among other essential aspects. Additionally, I
          have learned to navigate the dynamic challenges inherent in each
          project, from conceptualization to construction, and to quickly adapt
          to ever-evolving situations. It has also instilled in me a strong
          sense of teamwork and the ability to collaborate effectively, allowing
          me to deliver desired architectural solutions.
        </p>
        <h4 className="h-arq derecha">independent</h4>
        <p className="card-text">
          As an independent professional, I have worked in residential and
          commercial architecture, interior design, and furniture design. I have
          had the opportunity to work on projects of various scales, ranging
          from designing a piece of furniture for residential or commercial
          premises to executing a complete project of a house, from design with
          the client to construction management. This has taught me how to adapt
          to the unique needs of each client and project. I have acquired
          expertise in working with a wide range of materials and utilizing
          different construction methods, all in accordance with the specific
          requirements of the client, the project, and the environment.
        </p>
        <h4 className="h-arq derecha">future</h4>
        <p className="card-text">
          I am always looking for new challenges and opportunities to grow as a
          professional and as a person. I am eager to continue learning and
          developing my skills, planning to continue my studies in the near
          future, exploring the technological side of the architecture and
          construction industry. I am also interested in the field of
          sustainable architecture, and contribute to a more sustainable future.
        </p>
        <br />
        <br />
      </div>
    </>
  );
};

export default ArquitecturaTexto;
