import React from "react";
import { Col, Card } from "react-bootstrap";
import "./inicioGrilla.css";

const InicioTexto = () => {
  return (
    <Col className="col-lg-7 col-md-6 col-sm-12 col-12 mx-auto marg-sup">
      <Card className="bg-transparent border-0 just">
        <div className="card-body">
          <h5>
            <b>
              Passionate about architecture and fascinated by the possibilities
              of programming. Design, architecture and technology were always
              areas of great interest to me.
            </b>
          </h5>
          <br />
          <br />
          <p className="card-text">
            Nicolás is an architect and IT analyst. His passion for art and
            design led him to explore the possibilities offered by programming,
            expanding the horizon to the digital world. His multidisciplinary
            training allows him to offer comprehensive solutions to his clients.
            <br />
            <br />
            <br />
            In addition to his technical skills, his creativity and his ability
            to think outside the box have allowed him to venture into the world
            of graphic design and marketing. This combination of skills makes
            him a highly trained professional who can offer a range of services
            where he has vast experience. His sociable attitude makes it easy to
            work with, and his commitment to customer satisfaction is
            unquestionable.
            <br />
            <br />
            <br />
            He is always willing to continue learning. His proactive attitude,
            and his desire to continually improve, make him a professional in
            constant evolution, always in search of new skills and knowledge
            that help him stay updated in his areas of experience.
            <br />
            <br />
            <br />
            Nicolás offers architecture, marketing & design, and programming
            services. If you are looking for a professional who can offer
            complete solutions to your ideas, do not hesitate to contact him.
            This website is his tool to show more about him and his services.
            <br />
            <br />
            <br />
          </p>
        </div>
      </Card>
    </Col>
  );
};

export default InicioTexto;
